import image1 from '../images/2.jpg';
import image2 from '../images/1.jpg';
import image3 from '../images/3.jpg';
const data = [
  {
    image: image1,
    heading: 'Strength Training',
    details:
      'In this program, you are trained to improve your strength through many ways',
  },
  {
    image: image2,
    heading: 'Strength Training',
    details:
      'In this program, you are trained to improve your strength through many ways',
  },
  {
    image: image3,
    heading: 'Strength Training',
    details:
      'In this program, you are trained to improve your strength through many ways',
  },
  {
    image: image1,
    heading: 'Strength Training',
    details:
      'In this program, you are trained to improve your strength through many ways',
  },
];

export default data;
