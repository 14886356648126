import image1 from '../images/4.jpg';
import image2 from '../images/6.jpg';
import image3 from '../images/a.jpg';
export const testimonialsData = [
  {
    image: image1,
    review:
      'I made the right choice by choosing the fitclub and by choosing the right plan',
    name: 'Isabela Sharon',
    status: 'Entrepreneur',
  },

  {
    image: image2,
    review:
      'I made the right choice by choosing the fitclub and by choosing the right plan',
    name: 'Agatha ',
    status: 'Teacher',
  },
  {
    image: image3,
    review:
      'I made the right choice by choosing the fitclub and by choosing the right plan',
    name: 'Francis',
    status: 'States spokesperson',
  },
];
