import image1 from '../images/2.jpg';

const planData = [
  {
    icon: image1,
    name: 'BASIC PLAN',
    price: '25',
    features: [
      '2 Hours of exercises',
      'Free consulation to coaches',
      'Access to the community',
    ],
  },
  {
    icon: 2,
    name: 'BASIC PLAN',
    price: '25',
    features: [
      '2 Hours of exercises',
      'Free consulation to coaches',
      'Access to the community',
    ],
  },
  {
    icon: 3,
    name: 'BASIC PLAN',
    price: '25',
    features: [
      '2 Hours of exercises',
      'Free consulation to coaches',
      'Access to the community',
    ],
  },
];
export default planData;
